import React from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import Img from "gatsby-image";
import styled from "styled-components";
// Components
import { TwoColumnContentImage } from "../components/TwoColumnContentImage";
import { Box, Button, Center, Flex, Heading, Box as Section, Text } from "@chakra-ui/react";

const MotionBox = motion(Box);

const HomePage = ({ data }) => {
  return (
    <>
      <Section
        marginTop="0"
        minHeight="100vh"
        position="relative"
        overflow="hidden"
        background="radial-gradient(circle at bottom right, #1A202C 0, black)"
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <MotionBox
          position="absolute"
          w="100%"
          bottom="0"
          right="0"
          initial={{
            opacity: 0,
            y: "100%",
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 3.8,
            delay: 2.4,
            type: "spring",
            stiffness: 80,
          }}
        >
          <HeroImage fluid={data.main_background.childImageSharp.fluid} />
        </MotionBox>
        <Flex
          flexDirection="column"
          justifyContent={["start", null, null, "start"]}
          alignItems={["center", null, null, "start"]}
          p={6}
          pt={12}
        >
          <MotionBox
            w="100%"
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 0.8,
              type: "spring",
              stiffness: 80,
            }}
          >
            <Box mt={[12, null, null, 20]}>
              <Heading
                as="h3"
                color="brand.main"
                textTransform="uppercase"
                textAlign={["center", "center"]}
                fontSize={["4xl", null, null, "5xl"]}
                fontWeight="bold"
                textShadow="2px 2px 2px var(--color-black)"
                lineHeight="1"
                letterSpacing="0.01em"
              >
                Michael L. Wong, M.D.
              </Heading>
              <Heading
                as="h3"
                color="brand.main"
                textTransform="uppercase"
                textAlign={["center", "center"]}
                fontSize={["xl", null, null, "4xl"]}
                fontWeight="semibold"
                textShadow="2px 2px 2px var(--color-black)"
                lineHeight="1"
                letterSpacing="0.01em"
              >
                1946 - 2022
              </Heading>
            </Box>
          </MotionBox>
          <MotionBox
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.2,
              type: "spring",
              stiffness: 80,
            }}
          >
            <Box maxWidth="2xl" textAlign="center">
              <Text
                mt={[4]}
                fontWeight="bold"
                color="white"
                fontSize={["md", null, null, "lg"]}
                textAlign={["center", "center"]}
                lineHeight={1.8}
              >
                Dr. Wong was a loving husband, father and grandfather, who was known in the community for his 37 years as a pediatrician
                with the Corvallis Clinic.
              </Text>
            </Box>
          </MotionBox>
          <MotionBox
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.6,
              type: "spring",
              stiffness: 80,
            }}
          />
        </Flex>
      </Section>

      <Section
        backgroundColor="brand.lightShades"
        py={4}
        backgroundImage="linear-gradient(
0deg, var(--color-white), var(--color-white))"
      >
        <Center p={6} maxWidth="container.lg" minHeight={72} marginX="auto">
          <Box lineHeight="1.75">
            <Heading color="brand.main" mb="4" textAlign="center" textTransform="uppercase">
              Obituary
            </Heading>
            <Text color="brand.darkShades" fontSize={["md", null, null, "lg"]}>
              Dr. Michael L. Wong, quietly passed away June 10, 2022 in Corvallis, Oregon where he had been a pediatrician for 37 years. He
              was 75. He is survived by his wife, Fran, and three sons, Jamie, Matt and Noah; his older sister, Theresa Wu; his
              daughters-in-law, Alexis and Lilian; and his five grandsons Ethan, Eli, Jonah, Colton, and Rudy.
            </Text>
            <Text color="brand.darkShades" fontSize={["md", null, null, "lg"]}>
              Dr. Wong was born in Louisville, Kentucky and spent his childhood in Hong Kong and Richmond, Virginia. He completed a BA in
              History at the University of Virginia where he also attended medical school. Dr. Wong finished his pediatrics residency and an
              infectious disease fellowship at Washington University St. Louis Children's Hospital in Missouri.
            </Text>
            <Text color="brand.darkShades" fontSize={["md", null, null, "lg"]}>
              Arriving in Corvallis in 1976, he practiced medicine with a tireless dedication to his patients and a witty sense of humor
              that appealed to all ages. In his long career, he cared for multiple generations of families.
            </Text>
          </Box>
        </Center>
      </Section>

      <Section
        backgroundColor="brand.lightShades"
        py={4}
        backgroundImage="linear-gradient(
0deg, var(--color-gray-100), var(--color-gray-200))"
      >
        <Center p={6} maxWidth="container.lg" minHeight={72} marginX="auto">
          <Box lineHeight="1.75">
            <Heading color="brand.main" mt="6" textAlign="center" textTransform="uppercase">
              Memorial Service
            </Heading>
            <Heading color="brand.main" mt="2" mb="6" textAlign="center" fontSize="xl">
              Friday, June 17, 2022
              <br />
              1:30pm - 2:30pm PST
            </Heading>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden" }}>
              <iframe
                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                src="https://www.YouTube-nocookie.com/embed/7w7rT6a9jaE?modestbranding=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <Text mt="6" color="brand.darkShades" fontSize={["md", null, null, "lg"]}>
              Please donate to{" "}
              <Link color="teal.500" to="https://www.theaftd.org/" target="_blank">
                <Text color="blue" as="span" _hover={{ textDecoration: "underline" }}>
                  The Association for Frontotemporal Degeneration
                </Text>
              </Link>
              &nbsp; in memory of Dr. Michael Wong in lieu of flowers.
            </Text>
          </Box>
        </Center>
      </Section>

      <TwoColumnContentImage fluid={data.oceangate.childImageSharp.fluid} backgroundColor="var(--color-primary)">
        <Heading textTransform="uppercase" fontWeight="bold" fontSize={["2xl", null, null, "4xl"]}>
          Celebration of Life
        </Heading>
        <Text mt={4}>
          Please join us for a Celebration of Life in loving memory of Dr. Michael Wong at the Corvallis Community Center in the Chandler
          Ballroom.
        </Text>
        <Text mt={4} fontWeight="semibold">
          Date: Friday, June 17, 2022 <br />
          Time: 3:00 to 5:00 pm
        </Text>
        <Text mt={4}>
          2601 NW Tyler Avenue
          <br />
          Corvallis, OR 97330
        </Text>

        <Box mt={4}>
          <Link to="https://goo.gl/maps/wfUR77XQE9RV9XDo7" target="_blank">
            <Button variant="outline" color="brand.lightShades" _hover={{ color: "brand.lightAccent" }}>
              Directions
            </Button>
          </Link>
        </Box>
      </TwoColumnContentImage>

      <Section
        backgroundColor="brand.lightShades"
        py={4}
        backgroundImage="linear-gradient(
0deg, var(--color-gray-100), var(--color-gray-200))"
      >
        <Center p={6} maxWidth="container.lg" minHeight={72} marginX="auto">
          <Box lineHeight="1.75" width="100%">
            <Heading color="brand.main" textAlign="center" mb="8" textTransform="uppercase">
              Tribute Video
            </Heading>

            <div style={{ position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden" }}>
              <iframe
                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                src="https://www.YouTube-nocookie.com/embed/qJg_dFYBtwY?modestbranding=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Box>
        </Center>
      </Section>
    </>
  );
};

// Styled Components
const HeroImage = styled(Img)<HeroImageProps>`
  position: absolute !important;
  overflow: hidden;
  right: 50%;
  bottom: 0px;
  transform: translateX(50%);
  width: 0%;
  filter: drop-shadow(2.5rem 2.5rem 3.5rem var(--color-black));

  @media (min-width: 375px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 800px;
  }
  @media (min-width: 375px) and (max-height: 667px) {
    width: 275px;
  }
  @media (min-width: 411px) and (max-height: 731px) {
    width: 325px;
  }
  @media (min-width: 414px) and (max-height: 736px) {
    width: 325px;
  }
  @media (min-width: 1024px) and (max-height: 768px) {
    width: 300px;
  }
  @media (min-width: 1112px) and (max-height: 834px) {
    width: 350px;
  }

  @media ${({ theme }) => theme.mq.lg} {
    right: 50%;
    bottom: 0px;
    // transform: translateX(-70%);
    max-width: 680px;
  }
`;

// Types
type HeroImageProps = {
  fluid?: any;
};

export const pageQuery = graphql`
  query {
    main_background: file(relativePath: { eq: "dad-tulip.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 860) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oceangate: file(relativePath: { eq: "ocean-gate.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default HomePage;
